const origin = window.location.origin;

const OktaConfig = {
  issuer: '{IDP_ISSUER}',
  clientId: '{CLIENT_ID}',
  redirectUri: origin + '/implicit/callback',
  scopes: ['openid', 'email', 'profile', 'groups', 'offline_access'],
};

const SubAppOktaConfig = {
  ...OktaConfig,
  tokenManager: {
    autoRenew: false, // stop auto renew for all apps except okta-login App (1 app is responsible for updating tokens)
  },
};

export { OktaConfig, SubAppOktaConfig };
